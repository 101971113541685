import React from 'react';

import { colors } from '../../styles/variables';

import CookieModal from '../../components/organisms/CookieModal';
import { SectionAppearanceProvider } from '../../components/sections/SectionContext';

import Seo from '../../components/seo';
import {
    SectionHeroStart,
    SectionDataInNumbers,
    SectionOrderedItems,
    SectionVideoContainer,
    SectionIconItems,
    SectionAccordion,
    SectionInlineTiles,
    SectionBigTiles,
    SectionLogo,
} from '../../components/sections';

import {
    START_HERO_CONFIG,
    START_DATA_IN_NUMBERS_CONFIG,
    START_ORDERED_ITEMS_CONFIG,
    START_VIDEO_CONTAINER_CONFIG,
    START_ICON_ITEMS_CONFIG,
    START_ACCORDION_SECTION,
    START_INLINE_TILES_CONFIG,
    START_BIG_TILES_CONFIG,
    COOKIE_MODAL_CONFIG,
} from '../../content_config_start_ro';

const Index = () => (
    <div className="page-body">
        <SectionAppearanceProvider>
            <Seo
                lang="ro"
                title="Start PayPo.ro"
            />

            <SectionLogo />

            <SectionHeroStart
                config={ START_HERO_CONFIG }
            />

            <SectionDataInNumbers
                config={ START_DATA_IN_NUMBERS_CONFIG }
            />

            <SectionOrderedItems
                config={ START_ORDERED_ITEMS_CONFIG }
            />

            <SectionVideoContainer
                config={ START_VIDEO_CONTAINER_CONFIG }
                backgroundBottom={ colors.grayCultured }
            />

            <SectionIconItems
                config={ START_ICON_ITEMS_CONFIG }
                background={ colors.grayCultured }
            />

            <SectionAccordion
                config={ START_ACCORDION_SECTION }
                isColorSectionAbove={ true }
            />

            {/* <SectionInlineTiles
                config={ START_INLINE_TILES_CONFIG }
            /> */}

            <SectionBigTiles
                config={ START_BIG_TILES_CONFIG }
            />

            <CookieModal
                config={ COOKIE_MODAL_CONFIG }
            />
        </SectionAppearanceProvider>
    </div>
);

export default Index;
